<template>
    <v-report-table :data-function="getCustomerMessagesReport" :columns="columns">
        <template v-slot:column_channel="{ row }">
            <template v-if="row.channel_id === 1">
                <i v-tooltip="'Email'" class="fa fa-envelope text-2xl" />
            </template>
            <template v-if="row.channel_id === 2">
                <i v-tooltip="'SMS'" class="fa fa-sms text-2xl" />
            </template>
            <template v-if="row.channel_id === null">
                <i v-tooltip="'Application'" class="fa fa-desktop text-xl" />
            </template>
        </template>
    </v-report-table>
</template>

<script>
import ReportTable from "@/components/application/report/report-table.vue";
import ReportService from "@/services/modules/report-service";

export default {
    components: {
        "v-report-table": ReportTable,
    },
    data() {
        return {
            getCustomerMessagesReport: () => ReportService.getCustomerMessagesReport(),
            columns: {
                message_created_at_date: {
                    label: "Date",
                    fullWidth: false,
                },
                channel: {
                    label: "Channel",
                    fullWidth: false,
                    align: "center",
                },
                user_type: {
                    label: "User Type",
                    fullWidth: false,
                },
                message_count: {
                    label: "Message Count",
                    align: "right",
                    fullWidth: false,
                },
            },
        };
    },
};
</script>
