<template>
    <v-model-table v-bind="{ dataFunction: dataFunction }" :columns="columns" :show-toolbar="false">
        <template v-slot:column_channel="{ row }">
            <slot name="column_channel" :row="row" />
        </template>
    </v-model-table>
</template>

<script>
export default {
    name: "ReportTable",
    props: {
        dataFunction: {
            type: Function,
            required: false,
            default: null,
        },
        columns: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
};
</script>
